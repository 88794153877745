









































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerNewsModule from '@/store/modules/manager/news'
import {
  ITableOptions,
  ManagerNewsFromSourceSourceGetParams,
  NameValueResource,
  NewsSource,
  NewsSourceListResource,
} from '@/store/types'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'
// utils
import { tableFooterOptions } from '@/utils/constants'
import { tableOptionsToParams } from '@/utils/functions'

@Component({
  components: {
    Autocomplete,
    DateInput,
    Select,
    TableFooter,
  },
})
export default class CopyFromLessonModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  private get headers() {
    return [
      { sortable: false, cellClass: 'cell-icon cell-icon_first', text: '', value: 'data-table-select' },
      { sortable: false, text: 'Урок', value: 'title' },
      { sortable: false, text: 'Курс', value: 'course' },
      { sortable: false, text: 'Описание', value: 'shortText' },
      { sortable: false, text: 'Дата проведения', value: 'spendingAt' },
    ]
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private get sourceLessons() {
    return ManagerNewsModule.sourceLessons
  }

  private get subjects() {
    return DictionaryModule.subjects
  }

  private courses: NameValueResource[] = []

  private filter: ManagerNewsFromSourceSourceGetParams & ITableOptions = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
    subjectId: 1,
  }
  private filterDate: string[] = this.filter.dateFrom && this.filter.dateTo ? [this.filter.dateFrom, this.filter.dateTo] : []

  private footerOptions = tableFooterOptions

  private paginator = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private selected: NewsSourceListResource[] = []

  private total = 0

  private handleClose () {
    this.$emit('update:visible', false)
    this.$emit('close', false)
  }

  private handleCopyLesson() {
    if (!this.selected.length) {
      this.notifyError('Выберите урок.')
    } else {
      this.$emit('copyLesson', this.selected[0])
      this.handleClose()
    }
  }

  private fetchCoursesSelect() {
    ManagerNewsModule.fetchCoursesSelect({
      subjectId: this.filter.subjectId ?? undefined,
    })
      .then(response => this.courses = response)
  }

  @Bind()
  @Debounce(500)
  private fetchSourceLessons() {
    ManagerNewsModule.fetchSourcesNews({ source: NewsSource.LESSON, params: tableOptionsToParams(this.filter) })
      .then(response => this.total = response.meta.total)
      .catch(this.notifyError)
  }

  private handleFilter(field: keyof ManagerNewsFromSourceSourceGetParams | 'date', value: never) {
    if (field === 'date') {
      if ((value as string[]).length) {
        this.filter.dateTo = value[1]
        this.filter.dateFrom = value[0]
      } else {
        this.filter.dateTo = undefined
        this.filter.dateFrom = undefined
      }
      this.filter = {
        ...this.filter,
        page: 1,
      }
      this.fetchSourceLessons()
      return
    }

    if (field === 'subjectId') {
      this.filter.courseId = undefined
      this.fetchCoursesSelect()
    }

    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
    this.fetchSourceLessons()
  }

  private handleUpdateOptions() {
    this.fetchSourceLessons()
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value) {
      if (!this.sourceLessons.length) {
        this.fetchSourceLessons()
      }
      if (!this.courses.length) {
        this.fetchCoursesSelect()
      }
    }
  }
}
