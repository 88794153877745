



















import { Component, Mixins } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import NewsForm from '@/components/forms/news/NewsForm.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerNewsModule from '@/store/modules/manager/news'
import { CourseLabelResource, NewsLargeResource, NewsSourceListResource, NewsStoreData } from '@/store/types'

@Component({
  components: {
    NewsForm,
  },
})
export default class EditNews extends Mixins(NotifyMixin) {
  private form: NewsStoreData | null = null
  private newsID = +this.$route.params.newsID

  private courses: CourseLabelResource[] = []

  private get currentNews() {
    return ManagerNewsModule.currentNews
  }

  private mounted() {
    if (!this.currentNews || this.currentNews.id !== this.newsID) {
      this.fetchNews()
    } else {
      this.fillForm(this.currentNews)
    }
  }

  private fillForm(data: NewsLargeResource) {
    this.courses = data.courses
    this.form = {
      courseIds: data.courses.length ? data.courses.map(course => course.id) : [0],
      desktopMediaId: (data.desktopMedia ? [data.desktopMedia.id] : []) as unknown as number,
      displayMode: data.displayMode,
      mediaIds: data.media ? data.media.map(file => file.id) : [],
      mobileMediaId: (data.mobileMedia ? [data.mobileMedia.id] : []) as unknown as number,
      name: data.name,
      scheduleDates: data.scheduleDates,
      text: data.text,
      url: data.url,
      viewEndAt: data.viewEndAt,
      viewLimit: data.viewLimit,
      viewStartAt: data.viewStartAt,
    }
  }

  private handleCopyLesson(data: NewsSourceListResource) {
    if (this.form)
      this.form = {
        ...this.form,
        mediaIds: data.media.map(media => media.id),
        name: data.title,
        text: data.text,
      }
  }

  private handleCopyMaterial(data: NewsSourceListResource) {
    if (this.form)
      this.form = {
        ...this.form,
        mediaIds: data.media.map(media => media.id),
        name: data.title,
        text: JSON.parse(data.text),
      }
  }

  @Bind
  @Debounce(300)
  private fetchNews() {
    ManagerNewsModule.fetchCurrentNews(this.newsID)
      .then(response => {
        this.fillForm(response)
      })
      .catch(this.notifyError)
  }

  private editNews(form: NewsStoreData) {
    if (form) {
      const requestForm = {
        ...form,
        desktopMediaId: form.desktopMediaId && (form.desktopMediaId as unknown as number[])[0] || undefined as unknown as number,
        courseIds: form.courseIds.length === 1 && !form.courseIds[0] ? [] : form.courseIds,
        mobileMediaId: form.mobileMediaId && (form.mobileMediaId as unknown as number[])[0] || undefined as unknown as number,
      }
      ManagerNewsModule.editNews({ body: requestForm, id: +this.$route.params.newsID })
        .then(() => {
          this.notifySuccess('Новость успешно обновлена.')
        })
        .catch(this.notifyError)
    }
  }

  private removeNews() {
    ManagerNewsModule.removeNews(this.newsID)
      .then(() => {
        if (ManagerNewsModule.news.data.length === 1) {
          ManagerNewsModule.setNewsFilter({
            ...ManagerNewsModule.newsFilter,
            page: ManagerNewsModule.newsFilter.page > 1 ? ManagerNewsModule.newsFilter.page - 1 : 1,
          })
        }
        this.notifySuccess('Новость успешно удалена.')
        this.$router.replace({ name: 'manager.control.news' })
      })
      .catch(this.notifyError)
  }
}
